import {useEffect,useState,useRef,useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import QrImg from "../assets/qr-2.svg"
import Selector from "../commons/Selector"
import ButtonBox from "../commons/ButtonBox"

export default function Qr(){

    const [globalContext]=useContext(GlobalContext)
    const requestApi=useApi()
    const pointInput=useRef()
    const [pointVal,setPointVal]=useState()
    const [pointOptions,setPointOptions]=useState([])

    useEffect(()=>{
        requestApi("shops/shops_points",{context:"only_points"})
            .then(data=>{
                let newOptions=[]
                data.items.forEach(function(shop){
                    newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                })
                setPointOptions(newOptions)
                pointInput.current.setValue({label:newOptions[0]["label"],value:newOptions[0]["value"]})
            })
    },[])

    const downloadQr=(event,reference)=>{
        event.preventDefault()
        let link=document.createElement("a")
        link.setAttribute("download","")
        link.href=globalContext["base_url"]+"api/plain/qr/point/"+reference
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return(
        <Section>
            <SectionHeader
                title="Pagos con QR"
                intro="Empezá a cobrar con el kit QR."
                back=""
                button={{label:"Obtener QR",icon:"download",link:"",href:"",clickEvent:(event)=>{downloadQr(event,(pointVal?pointVal.point_id:""))}}}
                tabs=""
            />
            <SectionContent>
                <div className="boxes">
                    <div className="box">
                        <div className="box-info">
                            <div className="box-title">Cobrá de la forma más rápida</div>
                            <div className="box-description">
                                Los clientes te pagan en segundos y sólo necesitan su celular. No intercambiás tarjetas, comprobantes ni billetes. Es una forma de pago rápida y segura escaneando un código QR con el teléfono.
                            </div>
                        </div>
                        <div className="box-picture"><img src={QrImg} /></div>
                    </div>
                    <div className="box">
                        <div className="box-info">
                            <div className="box-description box-description-featured box-description-space">
                                Tus comercios tienen asignado un código QR único que podés descargar, imprimir y exhibir para que los clientes puedan leerlo con la cámara de sus teléfonos. Se abrirá una pantalla de pago en el navegador, en donde indicarán el monto y la forma de pago que prefieran. El pago se registrará al instante dentro de tu cuenta de Cajero24.
                            </div>
                            <Selector val={pointVal} setVal={setPointVal} ref={pointInput} placeholder="Punto de cobro" searchable={1} options={pointOptions} style="gray" />
                            <ButtonBox label="Descargar QR" clickEvent={(event)=>{downloadQr(event,(pointVal?pointVal.point_id:""))}} to="" link="" />
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-info">
                            <div className="box-description box-description-featured">
                                Además, podés crear links de pago con montos específicos y compartirlos utilizando códigos QR.
                            </div>
                            <ButtonBox label="Links de pago" clickEvent="" to="/c24/links-de-pago" link="" />
                        </div>
                    </div>
                </div>
            </SectionContent>
        </Section>
    )
    
}